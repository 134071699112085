import { useTranslation } from '@getpopsure/i18n-react';
import Session from '@getpopsure/session';
import {
  BottomOrRegularModal,
  Button,
  Card,
  CardProps,
  InformationBox,
  Spinner,
} from '@popsure/dirty-swan';
import { useGoogleLogin } from '@react-oauth/google';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { signOut } from 'actions/session';
import Markdown from 'components/markdown';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import endpoint from 'shared/api';
import { isMobileApp } from 'shared/util/isMobileApp';

import googleIcon from '../assets/google-icon.svg';
import { useGetAuthProviders } from './hooks/useGetAuthProviders';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID_OAUTH ?? '';

export const GoogleConnectButtonInternal = (props: Partial<CardProps>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { flagsReady } = useFlagsStatus();
  const hasGoogleLogin = useFlag('app_google_login');
  const [hasError, setHasError] = useState(false);
  const [isAccountAlreadyConnected, showAccountAlreadyConnected] =
    useState(false);
  const [showDisconnectConfirmation, setShowDisconnectConfirmation] =
    useState(false);
  const googleLoginCodeUrl = useQueryParamValue('googleLoginCode');

  const { authProviders, isLoading, error } = useGetAuthProviders();

  const connectedProvider = authProviders.find(
    ({ provider }) => provider === 'google'
  );
  const isConnected = Boolean(connectedProvider);
  const { mutateAsync: connectGoogleAccountRequest } = useMutation({
    mutationFn: async (token: string) => {
      const { data } = await endpoint.connectGoogleAccount(token);

      return data;
    },
    onError: () => showAccountAlreadyConnected(true),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['authProviders'] });
    },
  });

  const {
    isLoading: isLoadingDisconnect,
    mutateAsync: disconnectGoogleAccount,
  } = useMutation({
    mutationFn: async (token: string) => {
      const { data } = await endpoint.disconnectGoogleAccount(token);

      return data;
    },
    onSuccess: () => {
      setShowDisconnectConfirmation(false);
      queryClient.invalidateQueries({ queryKey: ['authProviders'] });
      dispatch(signOut());
    },
  });

  const googleLogin = useGoogleLogin({
    onSuccess: ({ code }) => connectGoogleAccountRequest(code),
    onError: () => {
      setHasError(true);
      captureException('Failed to connect Google account', {
        tags: {
          feature: 'GOOGLE_CONNECT',
        },
      });
    },
    flow: 'auth-code',
  });

  const connectGoogleAccount = () => {
    if (isMobileApp) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          googleLogin: true,
          redirectUrl: window.location.href,
        })
      );
    } else {
      googleLogin();
    }
  };

  useEffect(() => {
    if (isMobileApp && googleLoginCodeUrl) {
      connectGoogleAccountRequest(googleLoginCodeUrl);
    }
  }, [connectGoogleAccountRequest, googleLoginCodeUrl]);

  if (!hasGoogleLogin || error || !Session.isAuthenticated) {
    return null;
  }

  if (!flagsReady || isLoading) {
    return (
      <div className="d-flex ai-center jc-center pt24 pb24">
        <Spinner size="m" />
      </div>
    );
  }

  return (
    <>
      <Card
        title={
          isConnected
            ? t(
                'googleAuth.connectAccount.disconnect.title',
                'Disconnect Google account'
              )
            : t(
                'googleAuth.connectAccount.connect.title',
                'Link to Google account'
              )
        }
        description={
          !isConnected &&
          t(
            'googleAuth.connectAccount.connect.description',
            'Allows you to sign in using your Google account'
          )
        }
        icon={<img src={googleIcon} alt="Google icon" />}
        classNames={{ description: ' p-p tc-grey-600' }}
        titleVariant="medium"
        dropShadow={false}
        density="compact"
        {...props}
        onClick={
          isConnected
            ? () => setShowDisconnectConfirmation(true)
            : connectGoogleAccount
        }
      />

      {hasError && (
        <InformationBox
          variant="error"
          title={t(
            'googleAuth.connectAccount.connect.error.title',
            'An error occurred'
          )}
        >
          {t(
            'googleAuth.connectAccount.connect.error.description',
            'An error occurred while trying to connect your Google account. Please try again.'
          )}
        </InformationBox>
      )}

      <BottomOrRegularModal
        title={t(
          'googleAuth.connectAccount.connect.error.accountAlreadyConnected.title',
          'We couldn’t link your Google account'
        )}
        isOpen={isAccountAlreadyConnected}
        onClose={() => showAccountAlreadyConnected(false)}
      >
        <Markdown paragraphClassName="p-p pt16 mb8" className="p24 pt0">
          {t(
            'googleAuth.connectAccount.connect.error.accountAlreadyConnected.description',
            'Another Feather account is already linked with the Google email address you just used.\n\n If you’d like to continue, you’ll first need to **remove Google sign-in** from the Feather account that’s currently using it.'
          )}
        </Markdown>
      </BottomOrRegularModal>

      <BottomOrRegularModal
        title={t(
          'googleAuth.connectAccount.disconnect.title',
          'Disconnect Google account'
        )}
        isOpen={showDisconnectConfirmation}
        onClose={() => setShowDisconnectConfirmation(false)}
        footer={
          <Button
            className="w100"
            loading={isLoadingDisconnect}
            onClick={() =>
              disconnectGoogleAccount(String(connectedProvider?.id))
            }
          >
            {t(
              'googleAuth.connectAccount.disconnect.button',
              'Disconnect and log out'
            )}
          </Button>
        }
      >
        <Markdown paragraphClassName="p-p pt16 mb8" className="p24 pt0">
          {t(
            'googleAuth.connectAccount.disconnect.description',
            'You will be logged out of Feather and will need to sign-in again.'
          )}
        </Markdown>
      </BottomOrRegularModal>
    </>
  );
};

export const GoogleConnectButton = (props: Partial<CardProps>) => {
  const mobileAppSupportsGoogleLogin = Cookies.get('MOBILE_APP_VERSION_MAIN');

  if (isMobileApp && !mobileAppSupportsGoogleLogin) {
    return <></>;
  }

  if (window.Cypress || typeof jest !== 'undefined') {
    return <></>;
  }

  if (!googleClientId) {
    throw new Error('Google Client ID for signin is not defined');
  }

  return <GoogleConnectButtonInternal {...props} />;
};
