import { TFunction } from '@getpopsure/i18n-react';
import dayjs from 'dayjs';

import { questionPropLookup } from '../lookups';
import { DisabilityV2Questionnaire } from '.';

export const getPersonalInfoQuestionnaire = (
  t: TFunction
): DisabilityV2Questionnaire => [
  {
    id: 'signupIntro',
    required: true,
    type: 'INTRO',
    groupId: 'signup',
    props: {
      title: t('disabilityV2.qnr.signupIntro.title', 'Let’s get you signed up'),
      subTitle: t(
        'disabilityV2.qnr.signupIntro.subTitle',
        'We need a few pieces of information about you and we can figure out the rest.'
      ),
    },
  },
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'disabilityV2',
    },
    screen: {
      layout: 'Standalone',
      question: t(
        'disabilityV2.qnr.signup.email.question',
        'What’s your email address?'
      ),
      description: t(
        'disabilityV2.qnr.signup.email.description',
        'This policy will be accessible in your Feather account.'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      ...questionPropLookup(t).name,
    },
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 5 },
      },
      value: dayjs().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
      displayCalendar: true,
    },
    screen: {
      ...questionPropLookup(t).startDate,
    },
    validations: [
      {
        op: 'DateIsInFuture',
        msg: {
          type: 'Info',
          value: 'Earliest start date is {{value}}.',
        },
      },
      {
        op: 'Custom',
        fn: (answer: unknown) => {
          return dayjs(answer as string).date() === 1;
        },
        msg: () => {
          return {
            type: 'Info',
            msg: t(
              'disabilityV2.qnr.startDate.validation.errorFirstOfMonth',
              'Coverage start date should be on the first day of the month.'
            ),
          };
        },
      },
    ],
    groupId: 'signup',
  },
  {
    id: 'address',
    required: true,
    groupId: 'signup',
    type: 'ADDRESS',
    props: {},
    screen: {
      ...questionPropLookup(t).address,
    },
  },
  {
    id: 'citizenship',
    required: true,
    groupId: 'signup',
    type: 'COUNTRY_MULTI',
    props: {},
    screen: {
      ...questionPropLookup(t).citizenship,
    },
  },
  {
    id: 'employer',
    required: true,
    groupId: 'signup',
    type: 'INPUT',
    props: {},
    screen: {
      ...questionPropLookup(t).employer,
    },
  },
  {
    id: 'stayAbroad',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).stayAbroad,
    },
  },
  {
    id: 'stayAbroadDetails',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).stayAbroadDetails,
    },
  },
];
