import { useTranslation } from '@getpopsure/i18n-react';
import { Button } from '@popsure/dirty-swan';
import { useGoogleLogin } from '@react-oauth/google';
import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import endpoint from 'shared/api';
import { isMobileApp } from 'shared/util/isMobileApp';

import googleIcon from '../assets/google-icon.svg';
import styles from './GoogleLoginButton.module.scss';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID_OAUTH ?? '';

interface GoogleLoginButtonProps {
  disabled?: boolean;
  className?: string;
  onSuccess: () => void;
  onLoginError: () => void;
}

const GoogleLoginButtonInternal = ({
  className,
  disabled,
  onLoginError,
  onSuccess,
}: GoogleLoginButtonProps) => {
  const { t } = useTranslation();
  const { mutate: loginWithGoogle } = useMutation({
    mutationFn: (token: string) => {
      return endpoint.loginWithGoogle(token);
    },
    onSuccess,
    onError: (error) => {
      onLoginError();

      captureException(error, {
        tags: {
          feature: 'GOOGLE_LOGIN',
        },
        extra: {
          description: 'Failed to login with Google',
        },
      });
    },
  });

  const googleLoginCodeUrl = useQueryParamValue('googleLoginCode');

  const googleLogin = useGoogleLogin({
    onSuccess: ({ code }) => loginWithGoogle(code),
    onError: (error) => {
      onLoginError();
      captureException(error, {
        tags: {
          feature: 'GOOGLE_LOGIN',
        },
        extra: {
          description: 'Google login failed',
        },
      });
    },
    flow: 'auth-code',
  });

  const handleOnGoogleLogin = () => {
    if (isMobileApp) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ googleLogin: true, redirectUrl: window.location.href })
      );
    } else {
      googleLogin();
    }
  };

  useEffect(() => {
    if (isMobileApp && googleLoginCodeUrl) {
      loginWithGoogle(googleLoginCodeUrl);
    }
  }, [googleLoginCodeUrl, loginWithGoogle]);

  return (
    <Button
      className={classNames(styles.button, 'tc-grey-900', className)}
      onClick={handleOnGoogleLogin}
      disabled={disabled}
      type="button"
      variant="filledBlack"
      leftIcon={<img alt="Google Icon" src={googleIcon} />}
    >
      {t('components.email.googleSignin', 'Continue with Google')}
    </Button>
  );
};

export const GoogleLoginButton = (props: GoogleLoginButtonProps) => {
  const hasGoogleLogin = useFlag('app_google_login');
  const mobileAppSupportsGoogleLogin = Cookies.get('MOBILE_APP_VERSION_MAIN');

  if (isMobileApp && !mobileAppSupportsGoogleLogin) {
    return <></>;
  }

  if (!hasGoogleLogin || window.Cypress) {
    return <></>;
  }

  if (!googleClientId) {
    throw new Error('Google Client ID for signin is not defined');
  }

  return <GoogleLoginButtonInternal {...props} />;
};
