import { TFunction } from '@getpopsure/i18n-react';
import { calendly, faq, website } from '@getpopsure/private-constants';
import { illustrations } from '@popsure/dirty-swan';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';
import { coverageOneYearInTheFutureValidator } from 'SignupQuestionnaire/shared/customValidators';

import { createPostQuote } from './actions';
import { Postcode } from './components/Postcode';
import { Bike, BikeGroupIds } from './models';

export const BikeComponents = {
  POSTCODE: Postcode,
} as const;

export type BikeQuestionnaire = SignupQuestionnaireType<
  Bike,
  BikeGroupIds,
  typeof BikeComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  quote?: number
): BikeQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    props: {
      title: t('bikeV2.qnr.preQuote.intro.title', "Hey! Let's get you a quote"),
      subTitle: t(
        'bikeV2.qnr.preQuote.intro.subTitle',
        'Just 5 questions, it takes about a minute.'
      ),
    },
    screen: {
      continueButtonText: t('bikeV2.qnr.preQuote.intro.cta', 'Get started'),
    },
    groupId: 'preQuote',
  },
  {
    id: 'postalCode',
    required: true,
    type: 'POSTCODE',
    props: {
      placeholder: t(
        'bikeV2.qnr.preQuote.postalCode.placeholder',
        'Postal code'
      ),
    },
    screen: {
      question: t(
        'bikeV2.qnr.preQuote.postalCode.question',
        'What is your postcode in Germany?'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'dateOfBirth',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 80 },
        max: { op: 'subtract', type: 'years', value: 0 },
      },
    },
    screen: {
      question: t(
        'bikeV2.qnr.preQuote.dateOfBirth.question',
        'When were you born?'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'ageBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'bikeV2.qnr.preQuote.dateOfBirth.blocker.title',
        "We can't sign you up..."
      ),
      iconType: 'SHIELD',
      description: t(
        'bikeV2.qnr.preQuote.dateOfBirth.blocker.description',
        'We currently only cover people who are over 18 years old.\n\nYou can speak to one of our insurance experts if you have any questions'
      ),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'bikeV2.qnr.preQuote.dateOfBirth.blocker.cta',
            'Speak to us'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'isBikeAgeOverOneYear',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: {
        NO: t(
          'bikeV2.qnr.preQuote.isBikeAgeOverOneYear.no',
          'Less than one year old'
        ),
        YES: t(
          'bikeV2.qnr.preQuote.isBikeAgeOverOneYear.yes',
          'One year old or older'
        ),
      },
    },
    screen: {
      question: t(
        'bikeV2.qnr.preQuote.isBikeAgeOverOneYear.question',
        'How old is your bike?'
      ),
      additionalInfo: {
        title: '',
        description: t(
          'bikeV2.qnr.preQuote.isBikeAgeOverOneYear.additionalInfo.description',
          "Age refers to the bike's original purchase when brand new. If you purchased a vintage bike just two days ago, you would still answer 'One year old or more', for example."
        ),
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'purchasePrice',
    required: true,
    type: 'CURRENCY',
    props: {},
    screen: {
      question: t(
        'bikeV2.qnr.preQuote.purchasePrice.question',
        'How much did you pay for your bike and accessories?'
      ),
      description: t(
        'bikeV2.qnr.preQuote.purchasePrice.description',
        'Please include the costs for your bike, bike lock, and any accessories that you would like to cover.'
      ),
      additionalInfo: {
        title: t(
          'bikeV2.qnr.preQuote.purchasePrice.additionalInfo.title',
          "Why can't I just state the price of my bike?"
        ),
        description: t(
          'bikeV2.qnr.preQuote.purchasePrice.additionalInfo.description',
          "In order to [include your lock and attachments](https://faq.feather-insurance.com/en/articles/28596-how-can-i-cover-my-bike-lock-and-attachments-under-my-bike-insurance-policy) under your coverage, you'll need to add them to the price you paid for your bike. Please note these must be listed on the same invoice as the insured bike."
        ),
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'purchasePriceBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'bikeV2.qnr.preQuote.purchasePrice.blocker.title',
        'We can’t cover your bike'
      ),
      iconType: 'SHIELD',
      description: t(
        'bikeV2.qnr.preQuote.purchasePrice.blocker.description',
        'Our bike insurance doesn’t cover bikes that cost more than €12,000. You can speak to one of our insurance experts if you have any questions.'
      ),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'bikeV2.qnr.preQuote.purchasePrice.blocker.cta',
            'Speak to us'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'type',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: {
        BIKE: t('bikeV2.qnr.preQuote.type.bike', 'Bike'),
        E_BIKE: t('bikeV2.qnr.preQuote.type.eBike', 'E-Bike'),
      },
    },
    screen: {
      question: t(
        'bikeV2.qnr.preQuote.type.question',
        'What kind of bike is it?'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    required: true,
    type: 'QUOTEPAGE',
    groupId: 'preQuote',
    props: {
      config: {
        title: t('bikeV2.qnr.quote.title', 'Here is your quote'),
        headerImage: {
          src: imageTypeMapping.BIKE_V2,
          alt: 'bike',
        },
        priceHeader: {
          title: `€${quote}`,
          subTitle: t('bikeV2.qnr.quote.priceHeader.subtitle', 'per month'),
        },
        actions: {
          valuesMap: {},
          submitMap: [{ id: '1', planId: 'BASIC', price: quote }],
          items: [],
          button: {
            text: t('bikeV2.qnr.quote.actions.button.text', 'Continue'),
          },
        },
        coveredSection: {
          title: t('bikeV2.qnr.quote.coveredSection.title', "What's covered?"),
          layout: {
            cards: true,
            compact: true,
          },
          items: [
            {
              title: t('bikeV2.qnr.quote.coveredSection.1.title', 'Theft'),
              description: t(
                'bikeV2.qnr.quote.coveredSection.1.description',
                'Covers loss from theft, robbery and burglary, including from car roof racks and bicycle stand.'
              ),
              icon: {
                src: illustrations.theft,
                alt: t(
                  'bikeV2.qnr.quote.coveredSection.1.icon.alt',
                  'masked man'
                ),
              },
            },
            {
              title: t('bikeV2.qnr.quote.coveredSection.2.title', 'Damage'),
              description: t(
                'bikeV2.qnr.quote.coveredSection.2.description',
                'Covers repair costs arising from accidents, operating and production errors.'
              ),
              icon: {
                src: illustrations.bikeDamage,
                alt: t(
                  'bikeV2.qnr.quote.coveredSection.2.icon.alt',
                  'damaged wheel'
                ),
              },
            },
            {
              title: t(
                'bikeV2.qnr.quote.coveredSection.3.title',
                'Parts theft/damage'
              ),
              description: t(
                'bikeV2.qnr.quote.coveredSection.3.description',
                'Covers theft or damage of parts connected to the bike/e-bike that are included in the policy.'
              ),
              icon: {
                src: illustrations.bikeParts,
                alt: t(
                  'bikeV2.qnr.quote.coveredSection.3.icon.alt',
                  'wheel-less bike'
                ),
              },
            },
            {
              title: t(
                'bikeV2.qnr.quote.coveredSection.4.title',
                'Wear & tear'
              ),
              description: t(
                'bikeV2.qnr.quote.coveredSection.4.description',
                'Covers damages due to wear and tear on bikes less than 5 years old, including brakes and tires.'
              ),
              icon: {
                src: illustrations.bikeWear,
                alt: t(
                  'bikeV2.qnr.quote.coveredSection.4.icon.alt',
                  'bike wear'
                ),
              },
            },
            {
              title: t('bikeV2.qnr.quote.coveredSection.5.title', 'Vandalism'),
              description: t(
                'bikeV2.qnr.quote.coveredSection.5.description',
                'Protection against intentional damage and destruction by unknown third parties.'
              ),
              icon: {
                src: illustrations.vandalism,
                alt: t(
                  'bikeV2.qnr.quote.coveredSection.5.icon.alt',
                  'baseball bat'
                ),
              },
            },
            {
              title: t(
                'bikeV2.qnr.quote.coveredSection.6.title',
                'Battery protection'
              ),
              description: t(
                'bikeV2.qnr.quote.coveredSection.6.description',
                'Insured against theft, damage, and wear & tear (only if performance drops below 65% in the first 3 years).'
              ),
              icon: {
                src: illustrations.battery,
                alt: t(
                  'bikeV2.qnr.quote.coveredSection.6.icon.alt',
                  'charging battery'
                ),
              },
            },
          ],
        },
        notCoveredSection: {
          title: t(
            'bikeV2.qnr.quote.notCoveredSection.title',
            "What's not covered?"
          ),
          items: [
            {
              title: t(
                'bikeV2.qnr.quote.notCoveredSection.1.title',
                'Bikes used commercially'
              ),
              description: t(
                'bikeV2.qnr.quote.notCoveredSection.1.description',
                'Bikes used for commercial purposes, such as delivery or other jobs, are not eligible for coverage.'
              ),
              icon: {
                src: illustrations.bike,
                alt: t('bikeV2.qnr.quote.coveredSection.1.icon', 'bike'),
              },
            },
            {
              title: t(
                'bikeV2.qnr.quote.notCoveredSection.2.title',
                'Bike inspection fees'
              ),
              description: t(
                'bikeV2.qnr.quote.notCoveredSection.2.description',
                'Repairs for parts and labor are included; however, inspection fees from bike shops are not covered.'
              ),
              icon: {
                src: illustrations.visual,
                alt: t('bikeV2.qnr.quote.notCoveredSection.2.icon.alt', 'eye'),
              },
            },
            {
              title: t(
                'bikeV2.qnr.quote.notCoveredSection.3.title',
                'Scooters or e-bikes without pedal assistance'
              ),
              description: t(
                'bikeV2.qnr.quote.notCoveredSection.3.description',
                'Scooters or e-bikes powered solely by electric motors that require registration are not covered.'
              ),
              icon: {
                src: illustrations.bikeDestroyedDamaged,
                alt: t(
                  'bikeV2.qnr.quote.notCoveredSection.3.icon.alt',
                  'bike parts'
                ),
              },
            },
            {
              title: t(
                'bikeV2.qnr.quote.notCoveredSection.4.title',
                'Cosmetic repairs'
              ),
              description: t(
                'bikeV2.qnr.quote.notCoveredSection.4.description',
                'Cosmetic repairs like scratches or paint touch-ups aren’t covered. Only functional damage is included.'
              ),
              icon: {
                src: illustrations.shieldCross,
                alt: t(
                  'bikeV2.qnr.quote.notCoveredSection.4.icon.alt',
                  'shield with cross'
                ),
              },
            },
          ],
        },
        reviewBadge: true,
        faq: {
          title: t('bikeV2.qnr.quote.faq.title', 'Frequently asked questions'),
          data: [
            {
              questionId: 1,
              question: t(
                'bikeV2.qnr.quote.faq.1,question',
                'How do claims work?'
              ),
              answer: t(
                'bikeV2.qnr.quote.faq.1.answer',
                'In the event of theft or damage to your bike, simply file a claim through your Feather account.  If any additional information is needed, our bike insurance experts will email you and help guide you through the process. Claims take an average of 2-6 weeks to process, depending on the complexity.  Once completed, benefits for approved claims amount will be directly transferred to your bank account.'
              ),
            },
            {
              questionId: 2,
              question: t(
                'bikeV2.qnr.quote.faq.2.question',
                'What compensation can I expect?'
              ),
              answer: t(
                'bikeV2.qnr.quote.faq.2.answer',
                'The amount you are reimbursed is based on the price of the replacement bike or repairs within the limit of the total insured sum. You decide your total insured sum when purchasing the policy.'
              ),
            },
            {
              questionId: 3,
              question: t(
                'bikeV2.qnr.quote.faq.3.question',
                'Are extra bike parts and accessories covered?'
              ),
              answer: t(
                'bikeV2.qnr.quote.faq.3.answer',
                'Parts that are permanently attached to the bike and are needed for the bike to function are included as part of the coverage automatically (handlebars, seat, gears, etc…). Batteries, onboard computers, and anti-theft devices for e-bikes are also covered.\n\nAdditional accessories not essential to the function [must be included as part of the insured sum](https://faq.feather-insurance.com/en/articles/28596-how-can-i-cover-my-bike-lock-and-attachments-under-my-bike-insurance-policy) to be eligible for coverage. This includes bike locks, baskets, children seats, mudguards, helmets, trailers, and more.'
              ),
            },
            {
              questionId: 4,
              question: t(
                'bikeV2.qnr.quote.faq.4.question',
                'What are the bike eligibility requirements?'
              ),
              answer: t(
                'bikeV2.qnr.quote.faq.4.answer',
                '**Which bikes can be insured?** \n\n- Bicycles or e-bikes that do not require special registration\n\n- The bike owner must reside in Germany\n\n**Which bikes cannot be insured?**\n\n- E-bikes that require special registration or licenses to operate\n\n- Dirt bikes and velomobiles\n\n- Self-built and converted bicycles\n\n- Commercially used bicycles (for delivery or courier services)\n\n- Bikes used for marketing purposes'
              ),
            },
            {
              questionId: 5,
              question: t(
                'bikeV2.qnr.quote.faq.5.question',
                'Why would I need bike insurance if I already have household contents insurance?'
              ),
              answer: t(
                'bikeV2.qnr.quote.faq.5.answer',
                '### **Worldwide coverage**\n\nHousehold contents insurance only insures your bike against burglary from an apartment, house, or garage **without a bike coverage added on**. With bike insurance, you can protect your bike against theft and damage outside of your apartment as well.\n\n### Higher insured sum\n\nIn the event of theft, household insurance reimburses 1% of the total insured sum for the Extended coverage, and up to €3,000 on the Comprehensive plan. \n\nOur bike insurance, however, has a maximum insured sum up to €12,000 - so premium bikes and e-bikes can be covered in full.\n\n### Damage protection\n\nThe household contents insurance only protects you against theft, but having a bike policy also insures you for damages from theft, accidents, vandalism, and even routine repairs due to general wear and tear.'
              ),
            },
            {
              questionId: 6,
              question: t(
                'bikeV2.qnr.quote.faq.6.question',
                'What documents do I need to provide?'
              ),
              answer: t(
                'bikeV2.qnr.quote.faq.6.answer',
                "When purchasing a policy, it's important to [provide certain information](https://faq.feather-insurance.com/en/articles/28627-what-documents-do-i-need-when-purchasing-bike-insurance). The bike's frame number needs to be on file, and you also need to upload copies of the invoice and receipt from the bike's purchase as well as any accessories you would like covered.\n\nIn the event that you must submit a claim, you must [supply other document](https://faq.feather-insurance.com/en/articles/66018-what-documentation-is-needed-when-making-a-bike-insurance-claim)s based on the type of claim."
              ),
            },
            {
              questionId: 7,
              question: t(
                'bikeV2.qnr.quote.faq.7.question',
                'Why is the frame number or coding required?'
              ),
              answer: t(
                'bikeV2.qnr.quote.faq.7.answer',
                'When you take out insurance, you will be asked to provide your frame number. This is the only way to identify your bike or e-bike and guarantee insurance cover. Without a frame number, you cannot submit a claim.\n\nIf your insured bike does not have a frame number, you are obliged to have your bike coded immediately by an official specialist dealer, the Allgemeine Deutscher Fahrrad-Club eV (ADFC) or the police.'
              ),
            },
            {
              questionId: 8,
              question: t(
                'bikeV2.qnr.quote.faq.8.question',
                'Are there limits to coverage from wear and tear damage?'
              ),
              answer: t(
                'bikeV2.qnr.quote.faq.8.answer',
                'Repairs for wear and tear is insured if the bicycle is not older than 5 years at the time of damage. The calculation basis for this is the invoice date of the first invoice issued by the seller of the bicycle as a new item (not a used bicycle invoice) or the year of manufacture proven by you.'
              ),
            },
            {
              questionId: 9,
              question: t(
                'bikeV2.qnr.quote.faq.9.question',
                'What is the cancellation policy?'
              ),
              answer: t(
                'bikeV2.qnr.quote.faq.9.answer',
                'The minimum contract period is one year from the start date of the policy. After the first year, you can cancel at any time and the policy will terminate at the end of the current month.'
              ),
            },
          ],
          footer: t('bikeV2.qnr.quote.faq.footer', {
            defaultValue: 'See our [FAQ page]({{faqBase}}) for more answers.',
            faqBase: faq.base,
          }),
        },
        moreQuestionsSection: {
          bookACallGACategory: 'BIKE_INSURANCE',
          title: t(
            'bikeV2.qnr.quote.moreQuestionsSection.title',
            'Have more questions?'
          ),
          bookACallUrl: calendly.bikeInsurance,
          secondaryActionLink: faq.base,
          secondaryAction: t(
            'bikeV2.qnr.quote.moreQuestionsSection.secondaryAction',
            'Visit our FAQ'
          ),
        },
      },
    },
    screen: {
      layout: 'Standalone',
    },
  },
  {
    id: 'signupIntro',
    required: true,
    type: 'INTRO',
    props: {
      title: t(
        'bikeV2.qnr.signup.signupIntro.title',
        "Great! Let's sign you up"
      ),
      subTitle: t(
        'bikeV2.qnr.signup.signupIntro.subTitle',
        'Just 6 questions, it takes about 2 minutes.'
      ),
    },
    screen: {
      continueButtonText: t(
        'bikeV2.qnr.signup.signupIntro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: t('bikeV2.qnr.signup.name.question', "What's your name?"),
    },
    groupId: 'signup',
  },
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'bike',
    },
    screen: {
      layout: 'Standalone',
      question: t(
        'bikeV2.qnr.signup.postalCode.email.title',
        "What's your email address?"
      ),
      description: t(
        'bikeV2.qnr.signup.postalCode.email.description',
        'This policy will be accessible in your Feather account.'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'brand',
    required: true,
    type: 'INPUT',
    props: {},
    screen: {
      question: t(
        'bikeV2.qnr.signup.brand.question',
        'What brand is your bike?'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'model',
    required: true,
    type: 'INPUT',
    props: {},
    screen: {
      question: t(
        'bikeV2.qnr.signup.model.question',
        'What model is your bike?'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'frameNumber',
    required: true,
    type: 'INPUT',
    props: {},
    screen: {
      question: t(
        'bikeV2.qnr.signup.frameNumber.question',
        'Have your bike’s frame number?'
      ),
      description: t(
        'bikeV2.qnr.signup.frameNumber.description',
        'It is required for filing claims and will be stored in your account.'
      ),
      additionalInfo: {
        title: t(
          'bikeV2.qnr.signup.frameNumber.additionalInfo.title',
          'Where can I find the frame number?'
        ),
        description: t(
          'bikeV2.qnr.signup.frameNumber.additionalInfo.description',
          'Most bicycles have a frame number stamped into the metal under the bottom bracket. Occasionally, the frame number will be on the inside of the chain stay or on the down tube or top tube.\n\nIf your insured bike does not have a frame number, you must have your bike coded **within the first month of your policy** by an official specialist dealer, the _Allgemeine Deutscher Fahrrad-Club eV_ (ADFC), or the police.'
        ),
        moreInfoLink:
          'https://faq.feather-insurance.com/en/articles/28607-what-is-a-bike-frame-number-and-why-do-i-need-it-to-submit-a-claim',
      },
      skipDetails: {
        title: t(
          'bikeV2.qnr.signup.frameNumber.skipDetails.title',
          'Provide later'
        ),
        skipQuestion: { value: '' },
      },
    },
    groupId: 'signup',
  },
  {
    id: 'receipts',
    required: true,
    type: 'UPLOAD',
    props: {
      isQuestionnaire: true,
    },
    screen: {
      question: t(
        'bikeV2.qnr.signup.receipts.question',
        'Have your purchase receipts with you?'
      ),
      description: t(
        'bikeV2.qnr.signup.receipts.description',
        'Make sure to provide the receipts for the bike, lock, and accessories. They are required for making claims and will be stored in your account.'
      ),
      skipDetails: {
        title: t(
          'bikeV2.qnr.signup.receipts.skipDetails.title',
          'Provide later'
        ),
        skipQuestion: { value: [] },
      },
    },
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 1 },
      },
    },
    screen: {
      question: t(
        'bikeV2.qnr.signup.startDate.question',
        'When would you like to start your coverage?'
      ),
    },
    validations: [
      {
        op: 'DateIsInFuture',
        msg: {
          type: 'Info',
          value: t(
            'bikeV2.qnr.signup.startDate.errorMessage',
            'Earliest start date is tomorrow.'
          ),
        },
      },
      coverageOneYearInTheFutureValidator(),
    ],
    groupId: 'signup',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'CREATE_POST_QUOTE_SUBMIT_INFO',
      reviewValues: [
        {
          title: t('bikeV2.qnr.signup.review.name.title', 'Name'),
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
        {
          title: t('bikeV2.qnr.signup.review.brand', 'Brand'),
          value: { type: 'String', key: 'brand' },
          path: 'brand',
        },
        {
          title: t('bikeV2.qnr.signup.review.model', 'Model'),
          value: { type: 'String', key: 'model' },
          path: 'model',
        },
        {
          title: t('bikeV2.qnr.signup.review.frameNumber', 'Frame number'),
          value: { type: 'String', key: 'frameNumber' },
          path: 'frameNumber',
        },
        {
          title: t('bikeV2.qnr.signup.review.receipts', 'Purchase receipts'),
          value: {
            type: 'Array',
            key: 'receipts',
            transform: (el) => (el as Record<string, unknown>).name as string,
          },
          path: 'receipts',
        },
        {
          title: t('bikeV2.qnr.signup.review.startDate', 'Coverage start date'),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'startDate' },
          path: 'startDate',
        },
      ],
      verticalId: 'bike',
      insuranceType: 'BIKE_V2',
      createPostQuote: () => createPostQuote(t),
      confirmationText: t(
        'bikeV2.qnr.signup.review.confirmation',
        'By selecting "Continue", I confirm to have answered all questions truthfully. Knowingly omitting any relevant details entitles the insurer to cancel the contract—either retroactively or from the date the omission is discovered.'
      ),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'reviewCheckout',
    required: true,
    type: 'GENERIC_CHECKOUT',
    props: {
      type: 'BIKE_V2',
      policyInfoKind: 'BikeV2Policy',
      icon: imageTypeMapping.BIKE_V2,
      region: 'de',
      hasCheckoutDocuments: true,
      policyDetailsConfiguration: {
        policyDetails: [
          {
            title: t(
              'bikev2.qnr.checkout.policyDetails.startDate',
              'Requested start date'
            ),
            questionId: 'startDate',
            additionalInfo: {
              title: t(
                'bikev2.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
                'Requested start date'
              ),
              description: t(
                'bikev2.qnr.checkout.policydetails.requestedStartDate.additionalInfo.descriptionNoValue',
                'Your coverage should begin on the requested date but only after you have received the official confirmation.'
              ),
            },
          },
        ],
      },
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
];
